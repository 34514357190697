import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // Search used: data-controller="auto-hide"

  // Search used: data-auto-hide-delay-value=
  static values = {
    delay: { type: Number, default: 5000 },
  };

  connect() {
    // this.element.classList.add('transform', 'opacity-0', 'transition', 'duration-1000');
    setTimeout(() => {
      this.element.classList.add("animate__fadeOut");
      setTimeout(() => {
        this.element.remove();
      }, 1500);
    }, this.delayValue);
  }
}
