// Entry point for the build script in your package.json

// Avoid using Hotwire technology in frontend pages. Hotwire tightly
// integrates pages with controllers, which is not conducive to adapting
// frontend page templates to various themes. Since frontend page users
// are not restricted to the Rails stack, they have the freedom to use
// any JavaScript they desire to enhance the user experience of the webpage.
// import "@hotwired/turbo-rails";

import Rails from "@rails/ujs";
import "./controllers";
import Alpine from "alpinejs";

window.Alpine = Alpine;
Alpine.start();

Rails.start();
