import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // target blocks_pannel and properties_pannel
  static targets = ["blocks_pannel", "properties_pannel"];
  connect() {
    // when mouse is over a target, change copacity to 1
    this.blocks_pannelTarget.addEventListener("mouseover", () => {
      this.blocks_pannelTarget.style.opacity = 1;
      // slide from left to right
      this.blocks_pannelTarget.style.left = "0";
    });
    // when mouse is out of a target, change copacity to 0.5
    this.blocks_pannelTarget.addEventListener("mouseout", () => {
      this.blocks_pannelTarget.style.opacity = 0.5;
      // slide to left to hide
      this.blocks_pannelTarget.style.left = "-200px";
    });
    // when mouse is over a target, change copacity to 1
    this.properties_pannelTarget.addEventListener("mouseover", () => {
      this.properties_pannelTarget.style.opacity = 1;
      // slide from right to left to show
      this.properties_pannelTarget.style.right = "0";
    });
    // when mouse is out of a target, change copacity to 0.5
    this.properties_pannelTarget.addEventListener("mouseout", () => {
      this.properties_pannelTarget.style.opacity = 0.5;
      // slide to right to hide
      this.properties_pannelTarget.style.right = "-260px";
    });
  }

  disconnect() {
    // remove event listener when controller is disconnected
    this.blocks_pannelTarget.removeEventListener("mouseover", null);
    this.blocks_pannelTarget.removeEventListener("mouseout", null);
    this.properties_pannelTarget.removeEventListener("mouseover", null);
    this.properties_pannelTarget.removeEventListener("mouseout", null);
  }
}
