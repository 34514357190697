import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (window.scrollY > 140) {
      this.element.classList.add("scrolled");
    } else {
      this.element.classList.add("noscrolled");
    }
  }

  onScroll() {
    if (window.scrollY > 140) {
      if (!this.element.classList.contains("scrolled")) {
        this.element.classList.add("scrolled");
        this.element.classList.remove("noscrolled");
      }
    } else {
      if (this.element.classList.contains("scrolled")) {
        this.element.classList.remove("scrolled");
        this.element.classList.add("noscrolled");
      }
    }
  }
}
