import { Controller } from "@hotwired/stimulus";

/**
 * ModalController is a class that handles the behavior of a modal.
 * It has a target 'close' which is used to identify the close button of the modal.
 * It provides a method 'close' to handle the closing of the modal.
 * See render_modal method in app/helpers/application_helper.rb
 */
export default class extends Controller {
  /**
   * Targets are identifiers for elements in the DOM.
   * In this case, 'close' is the identifier for the close button of the modal.
   * Set data-modal-target="close" on a button tag
   */
  static targets = ["close"];

  /**
   * The connect method is a lifecycle callback used by StimulusJS.
   * It is called when the controller is connected to the DOM.
   */
  connect() {}

  /**
   * The close method handles the closing of the modal.
   * It removes the modal from the DOM when the user clicks outside the modal,
   * on the close button, or on a child element of the close button.
   *
   * @param {Event} e - The event object.
   */
  close(e) {
    // If user clicked outside the modal
    if (e.target === this.element) {
      this.element.remove();
      return;
    }

    // If user clicked on the close button
    if (e.target === this.closeTarget) {
      this.element.remove();
      return;
    }

    // Maybe user clicked on a child element of the button
    let parent_target = e.target.closest("button");
    if (parent_target === this.closeTarget) {
      this.element.remove();
    }
  }
}
