import { Controller } from "@hotwired/stimulus";
import { marked } from "marked";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
hljs.registerLanguage("dts", javascript);

// Connects to data-controller="markdown-display"
export default class extends Controller {
  connect() {
    this.element.innerHTML = marked.parse(this.element.innerHTML);
    hljs.highlightAll();
  }
}
