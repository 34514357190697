import { Controller } from "@hotwired/stimulus";

// data-controller="accordion"

export default class extends Controller {
  static targets = ["item"];
  connect() {
    this.itemTargets.forEach((item) => {
      item.classList.add("accordion-item");
    });
  }

  toggle(event) {
    if (event.target.tagName.toLowerCase() !== "h3") {
      return;
    }

    const currentItem = event.currentTarget;

    if (currentItem.classList.contains("is-open")) {
      currentItem.classList.remove("is-open");
    } else {
      this.itemTargets.forEach((item) => {
        item.classList.remove("is-open");
      });

      currentItem.classList.add("is-open");
    }
  }
}
