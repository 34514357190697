import { Controller } from "@hotwired/stimulus";

import * as basicLightbox from "../libs/basicLightbox";

export default class extends Controller {
  connect() {
    this.popshow = this.popshow.bind(this);
    this.element.addEventListener("click", this.popshow);
    this.instance;
  }

  disconnect() {
    this.element.removeEventListener("click", this.handleDrop);
  }

  popshow() {
    const instance = basicLightbox.create(`
      <img src="${this.element.src}" width="800" height="600">
    `);

    instance.show();
  }
}
