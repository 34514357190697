import { Controller } from "@hotwired/stimulus";
import * as ActiveStorage from "@rails/activestorage";
import { DirectUpload } from "@rails/activestorage";
import { humanFileSize } from "../libs/humanFileSize";

ActiveStorage.start();

export default class extends Controller {
  static targets = ["fileField"];

  connect() {
    this.upload_url = this.element.dataset.uploadUrl;
    this.host_url = this.element.dataset.hostUrl;
    this.handleDrop = this.handleDrop.bind(this);
    this.element.addEventListener("drop", this.handleDrop);
  }

  disconnect() {
    this.element.removeEventListener("click", this.handleDrop);
  }

  handle() {
    this.upload(this.fileFieldTarget.files[0]);
  }

  handleDrop(evt) {
    console.log("Handle drop", evt.dataTransfer.files[0]);
    const dT = new DataTransfer();
    dT.items.add(evt.dataTransfer.files[0]);
    evt.preventDefault();
    this.fileFieldTarget.files = dT.files;
    console.log("this.fileFieldTarget.files", this.fileFieldTarget.files);
    this.upload(this.fileFieldTarget.files[0]);
  }

  upload(file) {
    let upload = new DirectUpload(file, this.upload_url);
    upload.create((error, blob) => {
      if (error) {
        alert("Upload failed, please try later.");
      } else {
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.fileFieldTarget.name;
        this.element.appendChild(hiddenField);

        this.element.querySelector(".upload-preview .file_name").textContent = blob.filename;
        this.element.querySelector(".upload-preview .file_size").textContent = humanFileSize(blob.byte_size);
        let previewImage = this.element.querySelector(".upload-preview > img");
        if (blob.content_type.startsWith("image/")) {
          if (previewImage === null) {
            previewImage = document.createElement("img");
            this.element.querySelector(".upload-preview").appendChild(previewImage);
          }
          previewImage.setAttribute("src", `${this.host_url}rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`);
        } else {
          if (previewImage) {
            previewImage.style.display = "none";
          }
        }
      }
    });
  }
}
