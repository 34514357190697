import { Controller } from "@hotwired/stimulus";

// not used this controller yet
export default class extends Controller {
  connect() {
    // disable all href links
    const links = this.element.querySelectorAll("a");
    links.forEach((link) => {
      link.addEventListener("click", (event) => {
        event.preventDefault();
      });
    });
    // Find child element with class 'fixed'
    const header = this.element.querySelector(".fixed");
    // check if element exists
    if (header) {
      // if so, get the height of the element, including padding and border, and apply to the parent element content height
      const height = header.offsetHeight;
      this.element.style.height = `${height}px`;
      // and get the width of the element, and apply to the parent element content width
      const width = header.offsetWidth;
      this.element.style.width = `${width}px`;
      this.element.style.position = "fixed";
      this.element.style.zIndex = "99";
    }
  }

  disconnect() {}
}
