import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  connect() {
    let form_good = this.create_form("5", "Yes");
    let form_bad = this.create_form("-1", "No");

    this.element.appendChild(this.create_description());
    this.element.appendChild(form_good);
    this.element.appendChild(form_bad);
  }

  create_description() {
    let p = document.createElement("p");
    p.textContent = "该内容对您有帮助吗？";
    return p;
  }

  create_form(rating_value, label) {
    let form = document.createElement("form");
    form.setAttribute("action", this.urlValue);
    form.setAttribute("accept-charset", "UTF-8");
    form.setAttribute("method", "post");

    let form_field_source = document.createElement("input");
    form_field_source.setAttribute("name", "source");
    form_field_source.setAttribute("type", "hidden");
    form_field_source.setAttribute("value", doc_url);
    form.appendChild(form_field_source);

    let form_field_title = document.createElement("input");
    form_field_title.setAttribute("name", "title");
    form_field_title.setAttribute("type", "hidden");
    form_field_title.setAttribute("value", doc_title);
    form.appendChild(form_field_title);

    let form_field_rating = document.createElement("input");
    form_field_rating.setAttribute("name", "rating");
    form_field_rating.setAttribute("type", "hidden");
    form_field_rating.setAttribute("value", rating_value);
    form.appendChild(form_field_rating);

    let form_field_submit = document.createElement("input");
    form_field_submit.setAttribute("name", "commit");
    form_field_submit.setAttribute("type", "submit");
    form_field_submit.setAttribute("value", label);
    form_field_submit.setAttribute("class", "btn hover:bg-gray-100");
    form.appendChild(form_field_submit);

    return form;
  }
}
